
import RenderInstructionService from "../../../../services/RenderInstructionService"
import { ref, reactive } from "vue"
import RilutRuleForm from "./RilutRuleForm.vue"

export default {
  components: { RilutRuleForm },
  setup() {
    const service = new RenderInstructionService("riluts")
    const additionalFields = reactive({ lutFile: "" })
    const columns = reactive([{ field: "lutFile", header: "LutFile", width: "5rem" }])
    const collection = ref()

    function closeDialogs() {
      collection.value.addDialog = false
      collection.value.editDialog = false
      collection.value.duplicateDialog = false
      collection.value.reload()
    }
    return {
      service,
      additionalFields,
      columns,
      collection,
      closeDialogs,
    }
  },
}
