import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RilutRuleForm = _resolveComponent("RilutRuleForm")!
  const _component_RenderInstructionRuleCollection = _resolveComponent("RenderInstructionRuleCollection")!

  return (_openBlock(), _createBlock(_component_RenderInstructionRuleCollection, {
    ref: "collection",
    service: $setup.service,
    "edit-fields": $setup.additionalFields,
    columns: $setup.columns
  }, {
    addSlot: _withCtx(() => [
      _createVNode(_component_RilutRuleForm, {
        add: true,
        edit: false,
        duplicate: false,
        organization: $setup.collection.selectedOrganization,
        onClosedialogs: $setup.closeDialogs
      }, null, 8, ["organization", "onClosedialogs"])
    ]),
    editSlot: _withCtx(() => [
      _createVNode(_component_RilutRuleForm, {
        add: false,
        edit: true,
        duplicate: false,
        "selected-rule": $setup.collection.selectedItems[0].data,
        onClosedialogs: $setup.closeDialogs
      }, null, 8, ["selected-rule", "onClosedialogs"])
    ]),
    duplicateSlot: _withCtx(() => [
      _createVNode(_component_RilutRuleForm, {
        add: false,
        edit: false,
        duplicate: true,
        "selected-rule": $setup.collection.selectedItems[0].data,
        onClosedialogs: $setup.closeDialogs
      }, null, 8, ["selected-rule", "onClosedialogs"])
    ]),
    _: 1
  }, 8, ["service", "edit-fields", "columns"]))
}